<template>
  <div
    v-infinite-scroll="loadMore"
    infinite-scroll-disabled="busy"
    infinite-scroll-distance="10"
  >
    <r-page page-title="Overtime">
      <template #page-header>
        <h2 class="page-title">Overtime</h2>
        <r-menu-tab :level="3" />
      </template>

      <template #action-bar>
        <RequestHeader
          :filter="true"
          :search="true"
          :from="start_date"
          :to="end_date"
          :today="toDate"
          :checkedRows="checkedRows"
          :isMassUpdating="isMassUpdating"
          @onMassUpdate="massUpdate"
          @onSelectDate="selectDate"
          @clearDate="clearDate"
          :pageMenu="'request'"
          @onToggleFilterStatus="onToggleFilterStatus"
          :lodaData="isOvertimeListLoading"
          @onSearch="searchIconClick($event)"
        ></RequestHeader>
      </template>

      <template #page-content>
        <div id="table-request-employment" class="table-timeoff-type">
          <b-table
            :data="overtimeApproval"
            :per-page="perPage"
            :loading="isOvertimeListLoading"
            @page-change="onPageChange"
            ref:table
            hoverable
            narrowed
            backend-sorting
            :default-sort-direction="defaultSortOrder"
            :default-sort="[sortField, sortOrder]"
            checkable
            checkbox-position="left"
            :is-row-checkable="(row) => row.approvalStatus === 'waiting'"
            :checked-rows.sync="checkedRows"
            class="employment-directory-table"
            @sort="onSort"
            :sticky-header="stickyHeaders"
          >
            <template>
              <b-table-column label="No" v-slot="props" width="3%">
                <span class="is-capitalize">
                  {{ props.index + 1 }}
                </span>
              </b-table-column>

              <b-table-column
                field="full_name"
                label="Full Name"
                v-slot="props"
                sortable
                width="22%"
              >
                <div class="columns employment-table-name">
                  <img
                    class="log-activity-profile-picture"
                    :src="
                      determineProfilePicture(
                        props.row.requestedUser.profilePictureUrl,
                        props.row.requestedUser.gender
                      )
                    "
                  />
                  <span class="is-capitalize">
                    {{ props.row.requestedUser.fullName }}
                  </span>
                </div>
              </b-table-column>

              <b-table-column
                field="overtime_date"
                label="Date"
                v-slot="props"
                sortable
                width="12%"
              >
                <span class="is-capitalize">
                  {{ formatDate(props.row.overtimeDate) }}
                </span>
              </b-table-column>

              <b-table-column
                field="overtime_hours"
                label="Estimation Duration"
                v-slot="props"
                sortable
                width="14%"
              >
                <span class="is-capitalize">
                  {{ props.row.overtimeHours }}
                </span>
              </b-table-column>

              <b-table-column
                field="request_reason"
                label="Reason"
                v-slot="props"
                sortable
                width="20%"
              >
                <span class="is-capitalize">
                  {{ props.row.requestReason }}
                </span>
              </b-table-column>

              <b-table-column
                field="status"
                label="Status"
                v-slot="props"
                sortable
                width="8%"
              >
                <span
                  :class="`is-capitalize
                    ${determineFCByStatus(props.row.status)}`"
                >
                  {{ props.row.isCompleted ? 'completed' : props.row.status }}
                </span>
              </b-table-column>

              <b-table-column
                field="created_at"
                label="Created At"
                v-slot="props"
                sortable
                width="14%"
              >
                <span>
                  {{ formatDate(props.row.createdAt) }}
                </span>
              </b-table-column>

              <b-table-column label="Action" v-slot="props" width="5%">
                <b-icon
                  v-if="
                    props.row.overtimeStatus === 'approved' &&
                    props.row.finalApproverId === currentUser.id &&
                    !props.row.isCompleted &&
                    props.row.files.length
                  "
                  type="is-primary"
                  icon="check-bold"
                  custom-size="mdi-18px"
                  class="employment-edit-icon"
                  @click.native="onComplete(props.row.requestId)"
                ></b-icon>
                <b-icon
                  type="is-primary"
                  icon="pencil"
                  custom-size="mdi-18px"
                  class="employment-edit-icon"
                  @click.native="openModalDetail(props.row)"
                ></b-icon>
              </b-table-column>
            </template>
            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>No data found</p>
                </div>
              </section>
            </template>
          </b-table>
        </div>

        <OvertimeManagementModal
          :show="isModalOpen"
          :selectedOvertime="selectedOvertime"
          :userApproval="userApproval"
          :disableDeclined="disableDeclined"
          :isUpdatingDeclined="isUpdatingDeclined"
          :disableAccepted="disableAccepted"
          :isUpdatingAccepted="isUpdatingAccepted"
          :disableCanceled="disableCanceled"
          :currentUser="currentUser"
          @hide="closeModalEdit"
          @updateStatusApproval="updateStatusApproval"
          @onComplete="onComplete($event)"
        />
      </template>
    </r-page>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import moment from 'moment-timezone'
import {
  showToast,
  determineBackgroundColorByStatus,
  determineFontColorByStatus,
} from '@/services/util'
import RequestHeader from '../../../components/Header/RequestHeader.vue'
import OvertimeManagementModal from './OvertimeManagementModal.vue'

export default {
  components: {
    RequestHeader,
    OvertimeManagementModal,
  },
  data() {
    return {
      moduleType: 1, // Overtime
      stickyHeaders: true,
      isPaginated: false,
      perPage: 20,
      page: 0,
      lastPage: 0,
      checkedRows: [],

      defaultSortOrder: 'desc',
      sortField: 'created_at',
      sortOrder: 'desc',
      start_date: '',
      end_date: '',

      isOvertimeListLoading: false,
      isLoadMore: false,
      isModalOpen: false,
      isEditing: false,

      selectedOvertime: null,

      isUpdating: false,
      isUpdatingAccepted: false,
      isUpdatingDeclined: false,
      disableDeclined: false,
      disableAccepted: false,
      disableCanceled: false,
      isMassUpdating: false,

      // filter by date
      dates: [],
      toDate: moment(new Date()).format('YYYY-MM-DD'),
      selectedStatus: 'selectedStatus',
      status: 'waiting',
    }
  },
  computed: {
    ...mapGetters({
      overtimeApproval: 'overtimeModule/getOvertimeApprovalData',
      currentUser: 'auth/currentUser',
      userApproval: 'finalApproval/getUserApproval',
      overtimeApprovalDetail: 'overtimeModule/getOvertimeApprovalDetail',
    }),
  },
  methods: {
    ...mapActions({
      actionLoadOvertimeTeam: 'overtimeModule/fetchOvertimeApprovalData',
      actionUpdateStatusApproval: 'overtimeModule/updateOvertimeApproval',
      actionMassUpdateStatusApproval:
        'overtimeModule/massUpdateOvertimeApproval',
      actionFetchUserApproval: 'finalApproval/fetchUserApproval',
      actionFetchOvertimeApprovalDetail:
        'overtimeModule/fetchOvertimeApprovalDetail',
      actionCompleteApproval: 'overtimeModule/completeOvertimeApproval',
    }),

    ...mapMutations({
      setOvertimeApprovalData: 'overtimeModule/setOvertimeApprovalData',
    }),

    /**
     * Change status request to complete
     * @param {integer} id - selected data id
     */
    async onComplete(id) {
      let res = await this.$swal({
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'button is-primary',
          cancelButton: 'button',
        },
        text: `Are you sure you want to complete this request? You can't undo this action.`,
      })

      if (res && res.isConfirmed) {
        this.disableCanceled = true

        try {
          await this.actionCompleteApproval(id)
          showToast('Completed the overtime request!', 'is-success', 'is-top')
          await this.resetState()
        } catch (err) {
          console.log(err)
          showToast(err.response.data.message, 'is-danger', 'is-top')
        }
      }
    },

    /**
     * Clear date detail
     */
    async clearDate() {
      this.start_date = ''
      this.end_date = ''
      await this.resetState()
    },

    /**
     * Select Date Range of table data
     * @param {Date} val - selected date range
     */
    async selectDate(val) {
      this.start_date = moment(val[0]).format('YYYY-MM-DD')
      this.end_date = moment(val[1]).format('YYYY-MM-DD')

      await this.resetState()
    },

    /**
     * Toggle Filter Status
     * @param {Array} filterStatus - Array of status filter
     */
    async onToggleFilterStatus(filterStatus) {
      this.selectedStatus = filterStatus?.length < 1 ? '' : 'selectedStatus'
      this.status = filterStatus
      await this.resetState()
    },

    /**
     * Determine Profile picture from database
     * @param {String} picture - Picture URL
     * @param {String} gender - gender string
     * used if no picture is present ('F'/ 'M')
     * @return {String} default gender picture url
     */
    determineProfilePicture(picture, gender) {
      if (!picture) {
        if (gender === 'F') {
          // female
          return '/images/default-profile-picture-female.png'
        }
        // male
        return '/images/default-profile-picture-male.png'
      }

      return picture
    },

    /**
     * Determine background color by status
     * @param {String} status - status
     * @return {String} background color css class
     */
    determineBGClassByStatus(status) {
      return determineBackgroundColorByStatus(status)
    },

    /**
     * Set table status color
     * @param {String} status - request status
     * @return determineFontColorByStatus method
     */
    determineFCByStatus(status) {
      return determineFontColorByStatus(status)
    },

    /**
     * Set Format Date
     * @param {Date} str - date string
     * @return {Date} formatted date
     */
    formatDate(str) {
      if (str) {
        return moment(str).format('DD MMMM YYYY')
      }
    },

    /**
     * Load more overtime management list data
     */
    async loadMore() {
      if (this.page < this.lastPage) {
        this.isLoadMore = true
        await this.loadOvertimeTeamList()
        this.isLoadMore = false
      }
    },

    /**
     * Search table data
     * @param {String} evt - keyword search
     */
    async searchIconClick(evt) {
      await this.resetState(evt)
    },

    /**
     * Reset state of table
     * @param {String} searchEvent - keyword search (optional)
     */
    async resetState(searchEvent) {
      this.page = 0
      this.lastPage = 0
      this.setOvertimeApprovalData([])
      await this.loadOvertimeTeamList(searchEvent)
    },

    /**
     * Sort table
     * @param {String} field - name of field
     * @param {String} order - asc or desc
     */
    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.resetState()
    },

    /**
     * Change page behavior
     * @param {integer} page - number of page
     */
    onPageChange(page) {
      this.page = page
      this.loadOvertimeTeamList()
    },

    /**
     * Load overtime management list data.
     * @param {String} searchEvent - keyword search (optional)
     */
    async loadOvertimeTeamList(searchEvent) {
      this.isOvertimeListLoading = true

      try {
        const response = await this.actionLoadOvertimeTeam({
          perPage: this.perPage,
          page: ++this.page,
          sortField: this.sortField,
          sortOrder: this.sortOrder,
          search: searchEvent,
          start_date: this.start_date,
          end_date: this.end_date,
          selectedStatus: this.selectedStatus,
          status: this.status,
        })

        this.total = response.data.total
        this.lastPage = response.data.lastPage
      } catch (err) {
        console.log(err)
      }

      this.isOvertimeListLoading = false
    },

    /**
     * Open detail modal. If id present,
     * set form to include data from selected table id
     * @param {integer} row - table row index (optional)
     * @param {integer} id - selected data id (optional)
     */
    async openModalDetail(row, id) {
      if (id) {
        let response = await this.actionFetchOvertimeApprovalDetail(id)
        this.selectedOvertime = response.data.data
      } else {
        this.selectedOvertime = row
      }
      this.isModalOpen = true
    },

    /**
     * Close edit modal
     */
    closeModalEdit() {
      this.selectedOvertime = null
      this.isModalOpen = false
    },

    /**
     * Update multiple request status
     * @param {Object} status object
     */
    async massUpdate(status) {
      let ids = []

      this.checkedRows.forEach((c) => ids.push(c.id))

      this.isMassUpdating = true

      try {
        await this.actionMassUpdateStatusApproval({ status, ids })
        showToast('Update Success', 'is-success', 'is-top')
      } catch (err) {
        if (err.response.data.message) {
          showToast(err.response.data.message, 'is-danger', 'is-top')
        }
        console.log(err)
      }
      this.isMassUpdating = false
      this.checkedRows = []
    },

    /**
     * Update Approval Status
     * @param {integer} id - selected data id
     * @param {String} status - status string
     */
    async updateStatusApproval(param) {
      const { id, status } = param
      const currentUserId = this.currentUser.id

      if (status === 'declined') {
        this.disableAccepted = true
        this.isUpdatingDeclined = true
      } else {
        this.disableDeclined = true
        this.isUpdatingAccepted = true
      }

      try {
        await this.actionUpdateStatusApproval({
          id,
          status,
          reportToId: currentUserId,
        })
        showToast('Update Success', 'is-success', 'is-top')
      } catch (err) {
        if (err.response.data.message) {
          showToast(err.response.data.message, 'is-danger', 'is-top')
        }
        console.log(err)
      }

      this.closeModalEdit()

      if (status === 'declined') {
        this.disableAccepted = false
        this.isUpdatingDeclined = false
      } else {
        this.disableDeclined = false
        this.isUpdatingAccepted = false
      }
    },
  },

  async mounted() {
    this.setOvertimeApprovalData([])
    await this.loadOvertimeTeamList()
    if (this.$route.query.id) {
      await this.openModalDetail(null, this.$route.query.id)
    }
  },
}
</script>
