var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Modal',{class:'modal-log-activity-management',attrs:{"title":'Overtime Request',"show":_vm.show,"isSubmit":false,"isCancel":false},on:{"hide":_vm.hide},scopedSlots:_vm._u([{key:"form",fn:function(){return [_c('div',{staticClass:"columns is-multiline has-text-color-label"},[_c('div',{staticClass:"column is-9 is-flex is-align-items-center"},[_c('img',{staticClass:"selected-time-off-approval-profile-picture",attrs:{"src":_vm.determineProfilePicture(
              _vm.selectedOvertime.requestedUser.profilePictureUrl
            )}}),_c('div',[_c('span',{staticClass:"time-off-approval-modal-content-full-name has-text-weight-semibold"},[_vm._v(" "+_vm._s(_vm.selectedOvertime.requestedUser.fullName)+" ")]),_c('br'),_c('span',{staticClass:"time-off-approval-modal-content-position-name"},[_vm._v(" "+_vm._s(_vm.selectedOvertime.requestedUser.position)+" ")])])]),_c('div',{staticClass:"column is-3 is-flex is-align-items-center is-justify-content-flex-end"},[_c('div',{class:("time-off-approval-modal-status-container has-text-white has-text-weight-semibold " + (_vm.determineBGClassByStatus(
            _vm.selectedOvertime.status
          )))},[_vm._v(" "+_vm._s(_vm.selectedOvertime.overtimeStatus === 'completed' ? _vm.selectedOvertime.overtimeStatus : _vm.selectedOvertime.status)+" ")])]),_c('div',{staticClass:"column is-6"},[_c('p',{staticClass:"has-text-weight-bold"},[_vm._v(" Date "),_c('br'),_c('span',{staticClass:"has-text-weight-normal"},[_vm._v(" "+_vm._s(_vm.formatDate(_vm.selectedOvertime.overtimeDate))+" ")])])]),_c('div',{staticClass:"column is-6"},[_c('p',{staticClass:"has-text-weight-bold"},[_vm._v(" Estimation Duration "),_c('br'),_c('span',{staticClass:"has-text-weight-normal"},[_vm._v(" "+_vm._s(_vm.selectedOvertime.overtimeHours)+" "+_vm._s(_vm.selectedOvertime.overtimeHours > 1 ? 'hours' : 'hour')+" ")])])]),_c('div',{staticClass:"column is-12"},[_c('p',{staticClass:"has-text-weight-bold"},[_vm._v(" PIC "),_c('br'),_c('span',{staticClass:"has-text-weight-normal"},[_vm._v(" "+_vm._s(_vm.selectedOvertime.history ? _vm.selectedOvertime.history[0].fullName : '-')+" ")])])]),_c('div',{staticClass:"column is-12"},[_c('p',{staticClass:"has-text-weight-bold"},[_vm._v(" Request Reason "),_c('br'),_c('span',{staticClass:"has-text-weight-normal"},[_vm._v(" "+_vm._s(_vm.selectedOvertime.requestReason ? _vm.selectedOvertime.requestReason : '-')+" ")])])]),_c('div',{staticClass:"column is-12"},[_c('p',{staticClass:"has-text-weight-bold"},[_vm._v(" Approved By "),_c('br'),_c('TimeOffApprovalHistoryComponent',{staticClass:"has-text-color-label",attrs:{"approval-history":_vm.selectedOvertime.history}})],1)]),(_vm.selectedOvertime.status === 'waiting')?_c('div',{staticClass:"modal-approval-button-container"},[_c('b-button',{staticClass:"has-text-weight-bold has-text-black",attrs:{"outlined":"","type":"is-primary","disabled":_vm.disableDeclined || _vm.isUpdatingDeclined},on:{"click":function($event){return _vm.updateStatusApproval({
              id: _vm.selectedOvertime.id,
              status: 'declined',
            })}}},[_vm._v(" "+_vm._s(_vm.isUpdatingDeclined ? 'Declining...' : 'Decline')+" ")]),_c('b-button',{staticClass:"has-text-weight-bold",attrs:{"expanded":"","type":"is-primary","disabled":_vm.disableAccepted || _vm.isUpdatingAccepted},on:{"click":function($event){return _vm.updateStatusApproval({
              id: _vm.selectedOvertime.id,
              status: 'approved',
            })}}},[_vm._v(" "+_vm._s(_vm.isUpdatingAccepted ? 'Approving...' : 'Approve')+" ")])],1):_vm._e(),(
          _vm.userApproval.currentUserIsFinalApprover &&
          (_vm.selectedOvertime.status === 'approved' ||
            _vm.selectedOvertime.status === 'declined')
        )?_c('div',{staticClass:"modal-approval-button-container"},[(
            _vm.selectedOvertime.finalApproverId === _vm.currentUser.id &&
            _vm.selectedOvertime.status === 'approved' &&
            !_vm.selectedOvertime.isCompleted
          )?_c('b-button',{staticClass:"has-text-weight-bold has-text-black",attrs:{"outlined":"","type":"is-primary"},on:{"click":function($event){return _vm.onComplete(_vm.selectedOvertime.requestId)}}},[_vm._v(" Complete Overtime ")]):_vm._e()],1):_vm._e()])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }