<template>
  <section>
    <b-tabs
      class="filter-tabs"
      v-model="activeTab"
      @input="toggleFilterStatus(activeTab)"
      type="is-boxed"
    >
      <b-tab-item
        v-for="tab in listTab"
        :key="tab"
        :value="tab"
        :label="toTitleCase(tab)"
      />
    </b-tabs>
  </section>
</template>

<script>
export default {
  data: function () {
    return {
      // Default Active Tabs
      activeTab: 'waiting',
    }
  },
  props: {
    listTab: {
      default: () => [],
      required: true,
      type: Array,
    },
  },

  methods: {
    async toggleFilterStatus(status) {
      this.activeTab = status
      // emit
      this.$emit('on-toggle-filter', status === 'all' ? '' : status)
    },
    // Change Wording Case
    toTitleCase(string) {
      return string
        .toLowerCase()
        .split(' ')
        .map(function (word) {
          return word.charAt(0).toUpperCase() + word.slice(1)
        })
        .join(' ')
    },

    onReset() {
      this.filterStatus = ''
      this.$emit('onReset')
    },
  },
}
</script>
