<template>
  <div>
    <div class="level my-2 mx-3">
      <div class="level-left">
        <template v-if="filter">
          <div class="level-item">
            <div class="date-picker-container is-align-items-center is-flex">
              <b-datepicker
                v-model="rangeDates"
                :mobile-native="false"
                @input="selectDate($event)"
                class="attendance-datepicker cursor-pointer"
                range
              >
                <template v-slot:trigger>
                  <b-button
                    v-if="from && to"
                    icon-left="calendar-today"
                    class="overtime-calendar-btn"
                    icon-right="chevron-down"
                  >
                    {{ from }}
                    <span class="">to</span>
                    {{ to }}
                  </b-button>

                  <b-button
                    v-else
                    icon-left="calendar-today"
                    class="overtime-calendar-btn"
                    icon-right="chevron-down"
                  >
                    {{ today }}
                  </b-button>
                </template>
                <b-button
                  label="Clear"
                  type="is-danger"
                  icon-left="close"
                  outlined
                  @click="clearDate()"
                />
              </b-datepicker>
            </div>
          </div>
        </template>

        <div v-else class="level-item">
          <b-button
            tag="button"
            class="is-command"
            icon-left="plus"
            @click.native="openModalRequest()"
          >
            New
          </b-button>
        </div>
      </div>

      <div class="level-right">
        <div class="level-item" v-if="search">
          <SearchFilter
            :loadData="lodaData"
            @onSearch="searchIconClick($event)"
          />
        </div>
      </div>
    </div>
    <FilterTabs
      v-if="filter"
      @on-toggle-filter="onToggleFilterStatus($event)"
      :listTab="listTab"
    />
  </div>
</template>

<script>
import FilterTabs from '../FilterContent/TabsFilter.vue'
import SearchFilter from '../FilterContent/SearchFilter.vue'

export default {
  components: {
    FilterTabs,
    SearchFilter,
  },

  data() {
    return {
      listTab: ['waiting', 'approved', 'declined', 'canceled', 'all'],
      rangeDates: [],
    }
  },

  props: {
    checkedRows: Array,
    isMassUpdating: Boolean,
    from: String,
    to: String,
    today: String,
    search: Boolean,
    filter: Boolean,
    rightFilters: Boolean,
    open: Boolean,
    pageMenu: String,
    lodaData: Boolean,
    hasCompletedFilter: Boolean,
  },
  methods: {
    /**
     * Emit mass update method from parent component (not used yet)
     * @param {Object} evt - event listener
     */
    massUpdate(evt) {
      this.$emit('onMassUpdate', evt)
    },

    /**
     * Emit select date method from parent component
     * @param {Object} evt - event listener
     */
    selectDate(evt) {
      this.$emit('onSelectDate', evt)
    },

    /**
     * Clear date
     */
    clearDate() {
      this.$emit('clearDate')
      this.rangeDates = []
    },

    /**
     * open Request Modal
     */
    openModalRequest() {
      this.$emit('addNew')
    },

    /**
     * Toggle Status tab
     * @param {Object} evt - event listener
     */
    onToggleFilterStatus(evt) {
      this.$emit('onToggleFilterStatus', evt)
    },

    /**
     * Search Table data listener
     * @param {Object} evt - event listener
     */
    searchIconClick(evt) {
      this.$emit('onSearch', evt)
    },
  },
}
</script>
